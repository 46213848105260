import type { Resolvers } from '../../__codegen__/__react-query__/resolvers'

export const HotKeywords: Resolvers['HotKeywords'] = {
  keywords(parent) {
    return parent.keyword
  },
  queryInfo(parent) {
    return {
      testGroup: parent.query_info.test_group,
    }
  },
}
