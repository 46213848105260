import type { Resolvers } from '../../__codegen__/__react-query__/resolvers'

export const ShortcutDetail: Resolvers['ShortcutDetail'] = {
  __resolveType(parent) {
    return parent.__typename ?? null
  },
}

export const SearchShortcut: Resolvers['SearchShortcut'] = {
  id(parent) {
    return `SearchShortcut#${parent.id}`
  },
  _id(parent) {
    return parent.id
  },
  targetUri(parent) {
    return parent.targetUri || ''
  },
  imageUrl(parent) {
    return parent.image?.url ?? null
  },
  detail(parent) {
    if (parent.templateName === 'banner') {
      let decoration = null
      try {
        const { bg_color, text_color, active_bg_color } = JSON.parse(
          parent.decoration ?? ''
        )

        decoration = {
          bgColor: bg_color,
          textColor: text_color,
          activeBgColor: active_bg_color,
        }
      } catch (e) {
        console.error('Failed to shortcut decoration parse')
      }

      return {
        __typename: 'BannerShortcut',
        customText: parent.customText || '',
        customTextDisplay: parent.customTextDisplay || '',
        decoration,
      }
    }

    return {
      __typename: 'ServiceShortcut',
      title: parent.title || '',
      content: parent.content || '',
    }
  },
}

export const BannerShortcut: Resolvers['BannerShortcut'] = {
  customText(parent) {
    return parent.customText || ''
  },
  customTextDisplay(parent) {
    return parent.customTextDisplay || ''
  },
  decoration(parent) {
    return parent.decoration ?? null
  },
}

export const ServiceShortcut: Resolvers['ServiceShortcut'] = {
  title(parent) {
    return parent.title || ''
  },
  content(parent) {
    return parent.content || ''
  },
}
