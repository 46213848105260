import type { Resolvers } from '../../__codegen__/__react-query__/resolvers'

export const FleaMarketFilterRecommend: Resolvers['FleaMarketFilterRecommend'] =
  {
    priceRanges(parent) {
      return parent.price_ranges ?? []
    },
    categories(parent) {
      return parent.categories ?? []
    },
  }
