import type { Resolvers } from '../../__codegen__/__react-query__/resolvers'

export const FeedAutoComplete: Resolvers['FeedAutoComplete'] = {
  keywords(parent) {
    return (
      parent.keywords?.map((item, index) => ({
        id: `feedAutoComplete_keyword_${item.name}_${index}`,
        name: item.name ?? '',
        targetUri: item.targetUri ?? '',
      })) ?? []
    )
  },
  categoryKeywords(parent) {
    return (
      parent.categoryKeywords?.map((item, index) => ({
        id: `feedAutoComplete_categoryKeyword_${item.name}_${index}`,
        name: item.name ?? '',
        targetUri: item.targetUri ?? '',
      })) ?? []
    )
  },
  shortcutKeywords(parent) {
    return (
      parent.shortcutKeywords?.map((item, index) => ({
        id: `feedAutoComplete_shortcutKeyword_${item.name}_${index}`,
        name: item.name ?? '',
        targetUri: item.targetUri ?? '',
      })) ?? []
    )
  },
}
