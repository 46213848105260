import type { ServiceSearchV2 } from '../../services/search_v2'
import { getServiceSearchV2 } from '../../services/search_v2'
import type {
  ServiceAggregatedPreProcessType,
  ServiceSearchAggregatedType,
} from '../../aggregated/services'
import {
  getServiceAggregatedPreProcess,
  getServiceSearchAggregated,
} from '../../aggregated/services'
import type { ServiceXPropertyType } from '../../services/xProperty'
import { getServiceXProperty } from '../../services/xProperty'
import type { AppType, UserType } from '../context/FoundationProvider'
import type { ServiceSearchCommunityType } from '../../community/services'
import { getServiceSearchCommunity } from '../../community/services'
import type { SearchExperimentReferrerType } from '../../referrer/types'
import type { ServiceKarrotMarketType } from '../../flea-market/services'
import { getServiceKarrotMarket } from '../../flea-market/services'
import type { ServiceUserType } from '../../user/services'
import { getServiceUser } from '../../user/services'
import type { ServiceSearchBizPostType } from '../../business/services'
import { getServiceSearchBizPost } from '../../business/services'
import type { ServiceCategory } from '../../category/services'
import { getServiceCategory } from '../../category/services'
import type { ServiceAutoCompleteType } from '../../auto-complete/services'
import { getServiceAutoComplete } from '../../auto-complete/services'
import type { ServiceSearchFleaMarketType } from '../../flea-market/services/ServiceSearchFleaMarket'
import { getServiceSearchFleaMarket } from '../../flea-market/services/ServiceSearchFleaMarket'
import type { ServiceRecentSearchType } from '../../recent-search/services'
import { getServiceRecentSearch } from '../../recent-search/services'
import type { ServiceSearchPlaceType } from '../../place/services/ServiceSearchPlace'
import { getServiceSearchPlace } from '../../place/services/ServiceSearchPlace'
import type {
  ServiceKeywordNotificationType,
  DeprecatedServiceKeywordNotificationType,
} from '../../keyword-notification/services'
import {
  getServiceKeywordNotification,
  getDeprecatedServiceKeywordNotification,
} from '../../keyword-notification/services'
import type { ServiceFleamarketFilterRecommendType } from '../../flea-market/services/ServiceFleamarketFilterRecommend'
import { getServiceFleamarketFilterRecommend } from '../../flea-market/services/ServiceFleamarketFilterRecommend'
import type { ServiceV4KarrotmarketType } from '../../flea-market/services/ServiceV4Fleamarket'
import { getServiceV4Fleamarket } from '../../flea-market/services/ServiceV4Fleamarket'

export interface MyContext {
  app: AppType
  user: UserType
  experiment: SearchExperimentReferrerType
  regionId?: number
  services: {
    searchV2: ServiceSearchV2
    karrotMarket: ServiceKarrotMarketType
    xProperty: ServiceXPropertyType
    autoComplete: ServiceAutoCompleteType
    category: ServiceCategory
    aggregated: ServiceSearchAggregatedType
    aggregatedPreProcess: ServiceAggregatedPreProcessType
    fleaMarket: ServiceSearchFleaMarketType
    v4Fleamarket: ServiceV4KarrotmarketType
    fleaMarketFilterRecommend: ServiceFleamarketFilterRecommendType
    community: ServiceSearchCommunityType
    user: ServiceUserType
    bizPost: ServiceSearchBizPostType
    recentSearch: ServiceRecentSearchType
    place: ServiceSearchPlaceType
    keywordNotification: ServiceKeywordNotificationType
    deprecatedKeywordNotification: DeprecatedServiceKeywordNotificationType
  }
}

export function createContext({
  app,
  user,
  regionId,
  experiment,
}: {
  app: AppType
  user: UserType
  regionId?: number
  experiment: SearchExperimentReferrerType
}): MyContext {
  return {
    app,
    user,
    regionId,
    experiment,
    services: {
      searchV2: getServiceSearchV2({
        app,
        user,
      }),
      karrotMarket: getServiceKarrotMarket({
        app,
        user,
      }),
      xProperty: getServiceXProperty({ app, user }),
      autoComplete: getServiceAutoComplete({ app, user }),
      category: getServiceCategory({ app, user }),
      aggregated: getServiceSearchAggregated({
        app,
        user,
      }),
      aggregatedPreProcess: getServiceAggregatedPreProcess({
        app,
        user,
      }),
      fleaMarket: getServiceSearchFleaMarket({ app, user }),
      v4Fleamarket: getServiceV4Fleamarket({ app, user }),
      fleaMarketFilterRecommend: getServiceFleamarketFilterRecommend({
        app,
        user,
      }),
      community: getServiceSearchCommunity({
        app,
        user,
      }),
      user: getServiceUser({ app, user }),
      bizPost: getServiceSearchBizPost({ app, user }),
      recentSearch: getServiceRecentSearch({ app, user }),
      place: getServiceSearchPlace({ app, user }),
      keywordNotification: getServiceKeywordNotification({ app, user }),
      deprecatedKeywordNotification: getDeprecatedServiceKeywordNotification({
        app,
        user,
      }),
    },
  }
}
