import type { QueryResolvers } from '../../__codegen__/__graphql__/resolvers'
import { compact } from 'lodash-es'
import * as R from 'ramda'

export const CategoryQueries: QueryResolvers = {
  async fleaMarketCategories(parent, args, ctx) {
    return R.pipe(
      () =>
        ctx.services.category.getFleaMarketCategoryList({
          referrer: { experiment: ctx.experiment },
        }),
      R.andThen((resp) => resp?.categories ?? []),
      R.andThen(compact)
    )()
  },
}
