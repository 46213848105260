import type { Resolvers } from '../../__codegen__/__react-query__/resolvers'

export const SearchBizAd: Resolvers['SearchBizAd'] = {
  id(parent) {
    return `SearchBizAd#${parent.id}`
  },
  _id(parent) {
    return parent.id
  },
  bizProfile(parent) {
    return {
      name: parent.bizProfile?.name ?? '',
      category: parent.bizProfile?.category ?? '',
      description: parent.bizProfile?.description ?? '',
      imageUrl: parent.bizProfile?.imageUrl ?? '',
      region: parent.bizProfile?.region ?? '',
      reviewCount: parent.bizProfile?.reviewCount ?? 0,
      followerCount: parent.bizProfile?.followerCount ?? 0,
      targetUri: parent.bizProfile?.targetUri ?? '',
      hasCoupon: parent.bizProfile?.hasCoupon ?? false,
      storeOnSale: parent.bizProfile?.isPurchasable ?? false,
      reservable: parent.bizProfile?.isReservable ?? false,
      deliberationCode: parent.bizProfile?.deliberationCode ?? '',
    }
  },
}
