import type { CatalogProductAdResolvers } from '../../__codegen__/__react-query__/resolvers'

export const CatalogProductAd: CatalogProductAdResolvers = {
  id(parent) {
    return `CatalogProductAd#${parent.id}`
  },
  _id(parent) {
    return parent.id
  },
  targetUrl(parent) {
    return parent.targetUrl
  },
  clickTrackingUrlsList(parent) {
    return parent.clickTrackingUrls ?? []
  },
  impressionTrackingUrlsList(parent) {
    return parent.impressionTrackingUrls ?? []
  },
  thumbnailImageUrl(parent) {
    return parent.thumbnailImageUrl ?? ''
  },
  productName(parent) {
    return parent.productName ?? ''
  },
  mallName(parent) {
    return parent.mallName ?? ''
  },
  brandName(parent) {
    return parent.brandName ?? ''
  },
  adSourceName(parent) {
    return parent.adSourceName ?? ''
  },
  adSourceIconUrl(parent) {
    return parent.adSourceIconUrl ?? ''
  },
  originalPrice(parent) {
    return parent.originalPrice ?? 0
  },
  salePrice(parent) {
    return parent.salePrice ?? null
  },
  isLowestPrice(parent) {
    return parent.isLowestPrice ?? false
  },
}
