import type { Resolvers } from '../../__codegen__/__react-query__/resolvers'

export const SearchAction: Resolvers['SearchAction'] = {
  url(parent) {
    return parent.url || ''
  },
  method(parent) {
    return parent.method || ''
  },
}
