import type { SearchFleaMarketNodeResolvers } from '../../__codegen__/__react-query__/resolvers'
import { FLEA_MARKET_COMPONENT_TYPE } from '../constants'

export const SearchFleaMarketNode: SearchFleaMarketNodeResolvers = {
  componentType(parent) {
    switch (parent.componentType) {
      case 'FLEA_MARKET_COMPONENT_TYPE_FLEA_MARKET': {
        return FLEA_MARKET_COMPONENT_TYPE.FLEA_MARKET
      }
      case 'FLEA_MARKET_COMPONENT_TYPE_CATALOG_ADVERTISEMENT': {
        return FLEA_MARKET_COMPONENT_TYPE.CATALOG_ADVERTISEMENT
      }
      case 'FLEA_MARKET_COMPONENT_TYPE_CAROUSEL_ADVERTISEMENT': {
        return FLEA_MARKET_COMPONENT_TYPE.CAROUSEL_ADVERTISEMENT
      }
      case 'FLEA_MARKET_COMPONENT_TYPE_RELATED_KEYWORD': {
        return FLEA_MARKET_COMPONENT_TYPE.RELATED_KEYWORD
      }
      case 'FLEA_MARKET_COMPONENT_TYPE_NEIGHBOR_AD': {
        return FLEA_MARKET_COMPONENT_TYPE.NEIGHBOR_AD
      }
      case 'FLEA_MARKET_COMPONENT_TYPE_UNSPECIFIED':
      default: {
        return FLEA_MARKET_COMPONENT_TYPE.UNKNOWN
      }
    }
  },
}
