import type { Resolvers } from '../../../../__codegen__/__react-query__/resolvers'

export const Image: Resolvers['Image'] = {
  url(parent) {
    if (parent.url) {
      return parent.url
    }
    if (parent.file) {
      return parent.file
    }
    return null
  },
}
