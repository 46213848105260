import {
  GraphQLDate as Date,
  GraphQLDateTime as DateTime,
  GraphQLTime as Time,
} from 'graphql-scalars'

import type { Resolvers } from '../../../__codegen__/__react-query__/resolvers'
import { Image } from './Image'
import {
  SearchCommunity,
  SearchCommunityQueries,
  SearchCommunityNode,
} from '../../../community/graphql'
import {
  CatalogProductAd,
  CarouselCatalogProductAd,
  SearchBizAd,
  NeighborAd,
  DSPAdvertisementNode,
} from '../../../advertisement/graphql'
import { Realty } from '../../../realty/graphql'
import {
  SearchPlace,
  SearchPlaceQueries,
  SearchPlaceNode,
  SearchPlaceCreateBanner,
} from '../../../place/graphql'
import { KarrotGroup } from '../../../karrot-group/graphql'
import {
  SearchAggregatedQueries,
  AggregatedPreProcess,
  AggregatedAll,
  AggregatedPreProcessQueries,
} from '../../../aggregated/graphql'
import { HotKeywords, HotKeywordsQueries } from '../../../hot-keyword/graphql'
import {
  RelatedKeywords,
  RelatedKeyword,
  FleamarketRelatedKeywordsQueries,
} from '../../../related-keyword/graphql'
import { SearchCar, SearchCarFilter } from '../../../car/graphql'
import {
  FleaMarketFilterRecommend,
  FleaMarketFilterRecommendQueries,
  SearchFleaMarket,
  SearchFleaMarketNode,
  SearchFleaMarketQueries,
} from '../../../flea-market/graphql'
import {
  Region,
  RegionQueries,
  RegionRange,
} from '../../../flea-market-filter-region/graphql'
import { JobPost } from '../../../job/graphql'
import { SearchUser, SearchUserQueries } from '../../../user/graphql'
import { SearchBizPost, SearchBizPostQueries } from '../../../business/graphql'
import { SearchCommerce } from '../../../commerce/graphql'
import {
  SearchShortcut,
  ShortcutDetail,
  ServiceShortcut,
  BannerShortcut,
} from '../../../shortcut/graphql'
import {
  FeedAutoComplete,
  FeedAutoCompleteQueries,
} from '../../../auto-complete/graphql'
import { Category, CategoryQueries } from '../../../category/graphql'
import { SearchAction } from '../../../search-action/graphql'
import {
  RecentSearchQueries,
  RecentSearchMutations,
  RecentSearch,
  RecentSearchNode,
  RecentSearchMutationOutput,
  RecentSearchShortcut,
} from '../../../recent-search/graphql'
import {
  CreateUserKeywordNotificationOutput,
  KeywordUserNotificationMutations,
  KeywordNotificationQueries,
  UserKeyword,
} from '../../../keyword-notification/graphql'

const scalars: Resolvers = {
  Date,
  DateTime,
  Time,
}

export const resolvers: Resolvers = {
  Query: {
    ...RegionQueries,
    ...KeywordNotificationQueries,
    ...SearchAggregatedQueries,
    ...AggregatedPreProcessQueries,
    ...SearchCommunityQueries,
    ...SearchBizPostQueries,
    ...SearchPlaceQueries,
    ...SearchUserQueries,
    ...SearchFleaMarketQueries,
    ...FeedAutoCompleteQueries,
    ...HotKeywordsQueries,
    ...FleaMarketFilterRecommendQueries,
    ...FleamarketRelatedKeywordsQueries,
    ...RecentSearchQueries,
    ...CategoryQueries,
  },
  Mutation: {
    ...KeywordUserNotificationMutations,
    ...RecentSearchMutations,
  },
  Category,
  CreateUserKeywordNotificationOutput,
  Image,
  Region,
  RegionRange,
  SearchBizPost,
  SearchBizAd,
  SearchFleaMarket,
  SearchFleaMarketNode,
  SearchUser,
  SearchPlace,
  SearchPlaceNode,
  SearchPlaceCreateBanner,
  FeedAutoComplete,
  HotKeywords,
  JobPost,
  SearchCar,
  SearchCarFilter,
  UserKeyword,
  FleaMarketFilterRecommend,
  AggregatedPreProcess,
  AggregatedAll,
  SearchShortcut,
  ServiceShortcut,
  ShortcutDetail,
  BannerShortcut,
  SearchCommunity,
  SearchCommunityNode,
  KarrotGroup,
  SearchCommerce,
  DSPAdvertisementNode,
  CatalogProductAd,
  CarouselCatalogProductAd,
  Realty,
  RelatedKeywords,
  RelatedKeyword,
  NeighborAd,
  SearchAction,
  RecentSearch,
  RecentSearchNode,
  RecentSearchMutationOutput,
  RecentSearchShortcut,
  ...scalars,
}
